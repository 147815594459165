<template>
    <div class="globalBtn">
        <ion-item @click="launchCalendly" class="itemBtn" lines="none">
          <ion-icon slot="start" color="dark" :icon="calendarNumberOutline"/>
          <div class="titleBtn">
            <span class="textBtn" slot="end">RÉSERVER MON ENTRETIEN</span>
          </div>
        </ion-item>
      </div>
</template>

<script>
import  {IonItem, IonIcon} from '@ionic/vue';
import {calendlyUrl} from "../services/calendlyUrl";
import { Browser } from '@capacitor/browser';
import {calendarNumberOutline,} from 'ionicons/icons';
export default  {
  name: 'BookingCalendly',
  components: {IonItem, IonIcon},
    setup() {
      return {
          calendarNumberOutline,
      }
    },
  methods: {
    async launchCalendly() {
        await Browser.open({ url: calendlyUrl() })
    },
  }
}
</script>
<style scoped>
.globalBtn {
    background-color: #F7B62D !important;
    border: 1px solid #F7B62D;
    border-radius: 6px;
    padding: 0px;
    margin: 10px;
}

.globalBtn:hover {
    cursor: pointer;
    box-shadow:
            0 0.5px 2.1px -5px rgba(0, 0, 0, 0.015),
            0 1.1px 4.6px -5px rgba(0, 0, 0, 0.022),
            0 1.9px 7.6px -5px rgba(0, 0, 0, 0.027),
            0 2.9px 11.6px -5px rgba(0, 0, 0, 0.031),
            0 4.1px 16.7px -5px rgba(0, 0, 0, 0.035),
            0 5.8px 23.6px -5px rgba(0, 0, 0, 0.039),
            0 8.3px 33.4px -5px rgba(0, 0, 0, 0.043),
            0 12px 48.5px -5px rgba(0, 0, 0, 0.048),
            0 18.6px 74.8px -5px rgba(0, 0, 0, 0.055),
            0 33px 133px -5px rgba(0, 0, 0, 0.07);
}
.titleBtn {
    border-radius: 20px;
    padding: 3px;
    color : black;
    font-family: 'SFProTextSemiBold';
    font-size: 16px;
}
.textBtn {
  margin-left: 3px;
  margin-right: 3px;
}
.itemBtn {
  --background: var(--new-blue, var(--new-blue, transparent));
}
</style>
