import store from '@/store';

export const calendlyUrl = () => {
    let url = "https://calendly.com/alloopharma/monpremierentretien?";
    const name = store.getters.firstName;
    const lastName = store.getters.lastName;
    const email =  store.getters.emailUser;
    const phone = store.getters.phoneNumber;

    if (name) {
        url = `${url}&first_name=${name}`;
    }
    if (lastName) {
        url = `${url}&last_name=${lastName}`;
    }
    if (email) {
        url = `${url}&email=${email}`;
    }
    if (phone) {
        url = `${url}&phone=${phone}`;
        url = `${url}&a4=${phone}`;
    }

    return encodeURI(url);
};
